<template>
  <div
    class="hidden lg:flex h-full items-center justify-between bg-surface-10 border-l border-b border-surface-60 px-6"
  >
    <span class="s1 m lg:d py-6">{{ title }}</span>
    <div v-if="actionAreaVisible && userRole">
      <WebExpHeaderActionAreaClient v-if="userRole === 'Client'" />
      <WebExpHeaderActionAreaProvider v-else-if="userRole === 'Provider'" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useAuthStore } from "~/store/auth";

const route = useRoute();
const title = ref("");

const props = defineProps({
  actionAreaVisible: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const userRole = computed(() => authStore.getUserRole);

const updateTitle = (newTitle: string, newUserRole: string | null) => {
  if (newTitle === "Yolculuğum") {
    switch (newUserRole) {
      case "Client":
        title.value = "Yolculuğum";
        break;
      case "Provider":
        title.value = "Anasayfam";
        break;
      default:
        title.value = newTitle;
    }
  } else {
    title.value = newTitle;
  }
};

// Set the initial title on mount
onMounted(() => {
  updateTitle(route.meta.title?.toString(), userRole?.value);
});

// Watch for changes in route.meta.title or userRole
watch([() => route.meta.title, userRole], ([newTitle, newUserRole]) => {
  updateTitle(newTitle.toString(), newUserRole);
});
</script>
