<template>
  <AppBaseRow gap="small" items="center">
    <span class="l2">Yeni eşleşmeler için müsaitim</span>
    <Switch
      v-if="initialized"
      :value="availableForMatching"
      :onToggle="handleToggle"
      :disabled="switchDisabled"
    />
  </AppBaseRow>
</template>
<script setup lang="ts">
import { useAuthStore } from "~/store/auth";
import { useUiStore } from "~/store/ui";
import { useUserStore } from "~/store/user";

const initialized = ref(false);

const authStore = useAuthStore();
const userStore = useUserStore();
const uiStore = useUiStore();

const id = computed(() => authStore.user?.id || null);
const availableForMatching = computed(
  () => userStore.professionalProfile?.matchingPreferences?.availableForMatching
);

const switchDisabled = computed(() => userStore.isLoading);

onMounted(async () => {
  if (id.value) {
    await userStore.loadProfessionalProfile(id.value);
    initialized.value = true;
  }
});

const handleToggle = async (value: boolean) => {
  if (!value) {
    uiStore.openChangeAvailabilityModal();
  } else {
    await userStore.updateAvailableForMatching();
  }
};
</script>
