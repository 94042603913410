<template>
  <div
    @click="toggle"
    class="relative inline-block w-14 h-8 cursor-pointer"
    :class="{ 'opacity-50 cursor-not-allowed': disabled }"
  >
    <span
      :class="[
        'block w-full h-full rounded-full transition-colors duration-200',
        enabled ? 'bg-system-success-base' : 'bg-neutral-20',
      ]"
    ></span>
    <span
      :class="[
        'absolute left-0 top-0 bottom-0 my-auto w-7 h-7 rounded-full circle-shadow bg-surface-10 transition-transform duration-200 transform',
        enabled ? 'translate-x-1' : 'translate-x-6',
      ]"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    onToggle: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enabled: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.enabled = newValue;
    },
    enabled(newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      if (this.onToggle) {
        this.onToggle(!this.enabled);
      } else {
        this.enabled = !this.enabled;
      }
    },
  },
};
</script>

<style scoped>
.circle-shadow {
  filter: drop-shadow(0px 2px 4px rgba(24, 51, 47, 0.12));
}
</style>
