<template>
  <ButtonSecondaryMedium
    class="h-10"
    label="Uygulamayı indirin"
    @click="onClick"
  />
</template>
<script setup lang="ts">
import { useUiStore } from "~/store/ui";

const uiStore = useUiStore();
const router = useRouter();
const onClick = () => {
  uiStore.openAppDownload(router.currentRoute.value.path);
};
</script>
