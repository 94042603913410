<template>
  <AppBaseRow gap="small" items="center">
    <ButtonAppDownload />
    <ButtonPrimaryMedium
      label="Seans satın alın"
      class="h-10"
      @click="goToCheckout()"
    />
  </AppBaseRow>
</template>
<script lang="ts">
import { CheckoutNavigationSource, CheckoutViewStates } from "~/model/checkout";
import { AuthFlow } from "~/model/user";
import { useAuthStore } from "~/store/auth";
import { useCheckoutStore } from "~/store/checkout";
export default {
  setup() {
    const checkoutStore = useCheckoutStore();
    const authStore = useAuthStore();
    return { checkoutStore, authStore };
  },
  methods: {
    goToCheckout() {
      const checkoutStore = useCheckoutStore();
      this.authStore.setAuthFlow(AuthFlow.Signin);
      checkoutStore.setView(CheckoutViewStates.Initial);
      checkoutStore.setCheckoutNavigationSource(
        CheckoutNavigationSource.Homepage
      );
      this.$router.push(routes.purchase.path);
    },
  },
};
</script>
